import Vue from "vue";
import VueRouter from "vue-router";
import Date from "../views/Date";
import Category from "../views/Category";
import Location from "../views/Location";
import EventsByCategory from "../views/EventsByCategory";
import EventsByLocation from "../views/EventsByLocation";
import Map from "../views/Map";
import PapersView from "../views/PapersView";
import PdfView from "../views/PdfView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Date",
    component: Date
  },
  {
    path: "/category",
    name: "Category",
    component: Category
  },
  {
    path: "/location",
    name: "Location",
    component: Location
  },
  {
    path: "/bycategory/:category",
    name: "EventsByCategory",
    component: EventsByCategory
  },
  {
    path: "/bylocation/:room/:location",
    name: "EventsByLocation",
    component: EventsByLocation
  },
  {
    path: "/map/:name",
    name: "Map",
    component: Map
  },
  {
    path: "/papersview/:file",
    name: "PapersView",
    component: PapersView
  },
  {
    path: "/pdfview/:file",
    name: "PdfView",
    component: PdfView
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
