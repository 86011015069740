<template>
  <v-bottom-navigation
    fixed
    grow
    dark
  >
    <v-btn color="blue" plain x-large to="/" @click="$store.dispatch('changed')">
      <span>Program u danu</span>

      <v-icon>mdi-calendar-today</v-icon>
    </v-btn>

    <v-btn color="blue" plain x-large to="/category" @click="$store.dispatch('changed')">
      <span>Vrste programa</span>

      <v-icon>mdi-format-list-bulleted-type</v-icon>
    </v-btn>

    <v-btn color="blue" plain x-large to="/location" @click="$store.dispatch('changed')">
      <span>Dvorane</span>

      <v-icon>mdi-map-marker</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "TopBar",

  data: () => ({
  })
};
</script>
