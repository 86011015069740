<template>
  <v-container class="mb-12">
    <div class="qr"></div>
    <div class="cimg"></div>
    <v-row>
      <v-col v-for="(item,index) in maps" :key="index" cols="12">
        <v-card v-if="item.visible" class="elevation-3">
          <v-card-title>{{ item.name }}</v-card-title>
          <v-img width="100%" :src="'/'+item.file"></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Map",
  data: () => ({
  }),
  methods: {
    setVisible: function(objects, i) {
      var _objects = objects;
      var _i = i;
      window.setTimeout(() => {
        _objects[_i].visible = true;
        _i++;
        if (_i < _objects.length) this.setVisible(_objects, _i);
      }, 70);
    },
  },
  computed: {
    maps: function() {
      var plans = [];
      var program = this.$store.getters.program;
      var selLocation = this.$route.params.name;
      if(program && program.maps) {
        program.maps.forEach(m => {
          if(m.location==selLocation) {
            m.plans.forEach(p => {
              this.$set(p, 'visible', false);
              plans.push(p);
            });
          }      
        });
        if(plans.length>0)
            this.setVisible(plans,0);
      }
      return plans;
    }
  }
};
</script>

<style scoped>
.cimg
{
  background-image: url('/cimg.jpg');
  background-size: auto 10vw;
  height:10vw;
  margin-left:8vw;
}
.qr
{
  background-image: url('/QrOverlay.png');
  background-size: auto 10vw;
  height:10vw;
  width:10vw;
  float:left;
}
</style>