<template>
  <v-app>
    <top-bar />
    <v-main class="main">
        <router-view />
    </v-main>
    <bottom-bar />
  </v-app>
</template>

<script>
import BottomBar from "./components/BottomBar.vue";
import TopBar from "./components/TopBar.vue";

export default {
  name: "App",

  data: () => ({

  }),
  components: {
    TopBar,
    BottomBar,
  },
  mounted() {
    this.$store.dispatch("load_program");
  }
};
</script>

<style>
.main
{
  background:linear-gradient(90deg,lightgrey,cadetblue);
}
</style>
