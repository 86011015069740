<template>
<div class="pdiv">
    <v-btn color="amber" @click="$router.back()"><v-icon class="mr-2">mdi-arrow-left-circle</v-icon>Povratak</v-btn>
 <!--   <iframe width="100%" height="100%" :src="'/'+$route.params.file+'#toolbar=0&navpanes=0&scrollbar=0'"></iframe>-->
    <v-container fluid class="pt-0">
    <v-card v-for="(item,index) in papers" :key="index" :class="index==0 ? 'mb-0' : 'mb-4'" :elevation="index==0 ? 0 : 3" :color="index==0 ? 'transparent' : 'white'">
        <v-card-text>
        <span v-if="index>0">{{ item.authors }}</span><br/>
        <b>{{ item.title_hr }}</b><br/>
        {{ item.title_en}}<br/>
        </v-card-text>
    </v-card>
    <div class="my-12">
    </div>
    </v-container>
</div>
</template>

<script>
export default {
  name: "PapersView",
  data: () => ({
      papers:[]
  }),
  methods: {
    loadPapers: function() {
        var me = this;
      fetch('/'+this.$route.params.file)
        .then(function (response) {
          if (response.ok)
            response.text()
              .then(data => {
                // parse papers lines
                var lines = data.split('\n');
                var lcnt=1;
                var p;
                lines.forEach(l => {
                    if(lcnt==1) {
                      p = {
                        authors: '',
                        title_hr:'',
                        title_en:''
                        }
                      p.title_hr = l;  
                    } else if(lcnt==2) {
                        p.title_en = l;
                        me.papers.push(p);
                    } else if(lcnt % 4 == 0) {
                        p = {
                            authors: l,
                            title_hr:'',
                            title_en:''
                        }
                    } else if(lcnt % 4 == 1) {
                        p.title_hr = l;
                    } else if(lcnt % 4 == 2) {
                        p.title_en = l;
                        me.papers.push(p);
                    }
                    lcnt++;
                });
              });
        });
    }
  },
  mounted() {
      this.loadPapers();
  }
}
</script>

<style scoped>
.pdiv
{
    width: 100%;
    height: calc(100% - 64px);
}
</style>