<template>
  <v-container>
    <div class="qr"></div>
    <div class="cimg"></div>
    <div class="my-2 subtitle-2">{{ formatSelectedDate() }}</div>
    <v-timeline :dense="$vuetify.breakpoint.mdAndUp ? false : true" class="mb-3">
      <v-timeline-item v-for="(item,index) in $store.getters.eventsForDay" :key="index"
        :left="item.type=='c'" :right="item.type=='e'" :color="item.color"
      >
        <v-scale-transition>
          <v-hover v-slot="{ hover }" v-if="item.visible">
        <v-card v-if="item.visible" ripple :elevation="hover ? 6 : 3" :class="item.type=='c' ? '' : 'ml-4'" :color="item.color"> 
          <v-card-text>
            <p :class="(item.type=='c' ? 'font-weight-bold' : 'font-weight-bold ml-4')+(item.textcolor ? ' '+item.textcolor : '')">{{ item.time }}</p>
            <p :class="(item.type=='c' ? 'font-weight-bold' : 'ml-4')+(item.textcolor ? ' '+item.textcolor : '')">
              {{ item.title }}
            </p>
            <p v-if="item.file || item.sfile" :class="(item.type=='c' ? 'font-weight-bold' : 'ml-4')+(item.textcolor ? ' '+item.textcolor : '')">
              <v-btn text large v-if="item.file" :to="'/papersview/'+item.file" class="mr-3 pa-3" >
                <v-icon color="red">mdi-note-text-outline</v-icon> Referati
              </v-btn>
              <v-btn text large v-if="item.sfile && isMobile()" :href="'/'+item.sfile" class="pa-3">
                <v-icon color="grey">mdi-comment-text-outline</v-icon> Izvješće
              </v-btn>
              <v-btn text large v-if="item.sfile && !isMobile()" :to="'/PdfView/'+item.sfile" class="pa-3">
                <v-icon color="grey">mdi-comment-text-outline</v-icon> Izvješće
              </v-btn>
            </p>
            <p :class="(item.type=='c' ? 'font-weight-bold' : 'font-weight-bold ml-4')+(item.textcolor ? ' '+item.textcolor : '')">{{ item.room + (item.room!='' ? ', ' : '') + item.location }}</p>
          </v-card-text>   
         </v-card>
          </v-hover>
        </v-scale-transition>
      </v-timeline-item>
    </v-timeline>
    <div class="my-12"></div>
  </v-container>
</template>

<script>
import moment from 'moment';

export default {
  name: "Date",
  data: () => ({
  }),
  methods: {
    formatSelectedDate: function() {
      moment.locale('hr');
      return moment(this.$store.getters.selectedDate).format("dddd, D. MMMM, YYYY.");
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    }
  }
};
</script>

<style scoped>
.cimg
{
  background-image: url('/cimg.jpg');
  background-size: auto 10vw;
  height:10vw;
  margin-left:8vw;
}
.qr
{
  background-image: url('/QrOverlay.png');
  background-size: auto 10vw;
  height:10vw;
  width:10vw;
  float:left;
}
</style>