<template>
<div class="pdiv">
    <v-btn color="amber" @click="$router.back()"><v-icon class="mr-2">mdi-arrow-left-circle</v-icon>Povratak</v-btn>
    <iframe width="100%" height="100%" :src="'/'+$route.params.file+'#toolbar=0&navpanes=0&scrollbar=0'"></iframe>
</div>
</template>

<script>
export default {
  name: "PdfView",
  data: () => ({
  })
}
</script>

<style scoped>
.pdiv
{
    width: 100%;
    height: calc(100% - 64px);
}
</style>