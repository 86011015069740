<template>
  <v-container>
    <div class="qr"></div>
    <div class="cimg"></div>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" md="6" lg="6" xl="4" v-for="(item,index) in $store.getters.categories" :key="index">
        <v-scale-transition>
          <v-hover v-slot="{ hover }" v-if="item.visible">
        <v-card @click="$router.push('/bycategory/'+item.title)" v-if="item.visible" ripple :elevation="hover ? 6 : 3" class="text-center" :color="item.color"> 
          <v-card-text>
            <span :class="'font-weight-bold'+(item.textcolor ? ' '+item.textcolor : '')">{{ item.title }}</span>
         </v-card-text>
        </v-card>
          </v-hover>
        </v-scale-transition>
      </v-col>
    </v-row>
    <div class="my-12"></div>
  </v-container>
</template>

<script>

export default {
  name: "Category",
  data: () => ({
  })
};
</script>

<style scoped>
.cimg
{
  background-image: url('/cimg.jpg');
  background-size: auto 10vw;
  height:10vw;
  margin-left:8vw;
}
.qr
{
  background-image: url('/QrOverlay.png');
  background-size: auto 10vw;
  height:10vw;
  width:10vw;
  float:left;
}
</style>
