<template>
  <v-app-bar app dark color="green darken-3">
    <v-slide-group v-model="date">
        <v-slide-item v-for="(item,index) in $store.getters.programDates" v-slot:default="{ active, toggle }"
                    :key="index">
          <v-btn text @click="toggle" :color="active ? 'yellow' : 'black'">
          {{ formatAsDayName(item) }}
        </v-btn>
        </v-slide-item>
    </v-slide-group>
  </v-app-bar>
</template>

<script>
import moment from "moment";

export default {
  name: "TopBar",

  data: () => ({
    date:null
  }),
  watch: {
    date: function(newval) {
      this.$vuetify.goTo(0);
      this.$store.dispatch("date_changed", newval);
      if(this.$route.params.category || this.$route.params.location) {
        try {
          this.$vuetify.goTo('#day'+this.$store.getters.selectedDate);
        } catch {
          console.log("cannot scroll to selected date");
        }
      }
    },
    selectedDateIndex: function(newval) {
      if(!this.date)
        this.date = newval;
    }
  },
  computed: {
    selectedDateIndex() {
      return this.$store.getters.selectedDateIndex;
    }
  },
  methods: {
    formatAsDayName: function(date) {
      moment.locale('hr');
      return moment(date).format('dddd');
    }
  } 
};
</script>
