import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";

Vue.use(Vuex);

function setVisible(objects, i) {
  var _objects = objects;
  var _i = i;
  window.setTimeout(() => {
    _objects[_i].visible = true;
    _i++;
    if (_i < _objects.length) setVisible(_objects, _i);
  }, 70);
}

export default new Vuex.Store({
  state: {
    program: null,
    firstDate: null,
    lastDate: null,
    selectedDate: null,
    changeCnt: 0
  },
  mutations: {
    set_program(state, program) {
      state.firstDate = program.schedule[0].day;
      state.lastDate = program.schedule[program.schedule.length - 1].day;
      var cdate = moment();
      if (cdate < moment(state.firstDate))
        state.selectedDate = state.firstDate;
      else if (cdate > moment(state.lastDate))
        state.selectedDate = state.lastDate;
      else
        state.selectedDate = cdate.format('YYYY-MM-DD');
      state.program = program;
    },
    set_date(state, date) {
      state.selectedDate = moment(state.firstDate).add(date, 'days').format("YYYY-MM-DD");
    },
    inc_change_cnt(state) {
      state.changeCnt++;
    }
  },
  actions: {
    load_program({ commit }) {
      fetch('/program.json')
        .then(function (response) {
          if (response.ok)
            response.json()
              .then(data => commit("set_program", data));
        });
    },
    date_changed({ commit }, date) {
      commit("set_date", date);
    },
    changed({ commit }) {
      commit("inc_change_cnt");
    }
  },
  getters: {
    program: state => state.program,
    programDates: state => {
      var dates = [];
      if (state.program)
        state.program.schedule.forEach(e => dates.push(e.day));
      return dates;
    },
    eventsForDay: state => {
      var events = [];
      if (state.program && state.changeCnt >= 0) {
        var categories = state.program.schedule.find(e => e.day == state.selectedDate).categories;
        categories.forEach(c => {
          c.type = "c";
          Vue.set(c, 'visible', false);
          events.push(c);
          c.events.forEach(e => {
            e.type = "e";
            Vue.set(e, 'visible', false);
            events.push(e);
          });
        });
      }
      if (events.length > 0) setVisible(events, 0);
      return events;
    },
    categories: state => {
      var categories = [];
      if (state.program && state.changeCnt >= 0) {
        state.program.schedule.forEach(s => {
          s.categories.forEach(c => {
            Vue.set(c, 'visible', false);
            if (!categories.some(e => e.title == c.title))
              categories.push(c);
          });
        });
      }
      if (categories.length > 0) setVisible(categories, 0);
      return categories;
    },
    locations: state => {
      var locations = [];
      if (state.program && state.changeCnt >= 0) {
        state.program.locations.forEach(s => {
          Vue.set(s,'visible',false);
          locations.push(s);
        });
      }
      if (locations.length > 0) setVisible(locations, 0);
      return locations;
    },
    maps: state => {
      var maps = [];
      if (state.program && state.changeCnt >= 0) {
        state.program.maps.forEach(s => {
          Vue.set(s,'visible',false);
          maps.push(s);
        });
      }
      if (maps.length > 0) setVisible(maps, 0);
      return maps;
    },
    selectedDate: state => state.selectedDate,
    selectedDateIndex: state => moment(state.selectedDate).diff(moment(state.firstDate), 'days'),
  },
  modules: {},
});
